<template>
  <div id="Plan">
    
    <el-card class="box-card">
      <el-button @click="downLaond('#', 'JavaScript.pdf')">下载</el-button>
      <el-button @click="pdfPrintAll">打印</el-button>
      <div class="pdf" v-show="fileType === 'pdf'">
        <pdf
        style="max-width:900px;margin:10px auto"
          ref="pdf"
          :src="src"
          :page="currentPage"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          @loaded="loadPdfHandler"
        >
        </pdf>
      </div>
      <div class="btnBox">
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="1"
          @prev-click="prevClick"
          @next-click="nextClick"
          @error="pdfError($event)"
          layout="prev, pager, next, jumper"
          :total="pageCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      page: 1,
      fileType: "pdf", // 文件类型
      src: "JavaScript.pdf", // pdf文件地址
    };
  },
  watch: {},
  methods: {
    //下载

    downLaond(href, title) {
      const a = document.createElement("a");
      a.setAttribute("href", href);
      a.setAttribute("download", title);
      a.click();
    },
    //错误
    pdfError(error) {
      console.error(error);
    },
    //打印
    pdfPrintAll() {
      this.$refs.pdf.print();
    },
    prevClick(page) {
      console.log("prevClick", page);
    },
    nextClick(page) {
      console.log("nextClick", page);
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    },
  },
  created() {
    this.src=this.$route.query.pdf;
    console.log(this.src);
    this.src = pdf.createLoadingTask(this.src);
  },
};
</script>
<style >
</style>
<style lang="less" scoped>
.btnBox {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    padding: 0 10px;
  }
}
</style>